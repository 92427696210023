:root{
  --primary-color:#000000;
  --secondary-color:#A63E42;
  --third-color:#DEDEDE;
  --third-color-light:#F3F0E9;
  --fourth-color: #909090;

  
  touch-action: pan-x pan-y;
 
}

@media (min-width:1200px){
  .container{
    max-width: 1280px!important;
  }
  
}

*{
  margin:0;
  padding:0;
  box-sizing: border-box;
}

.img-zoom-container {
  position: relative;
}

.img-zoom-lens {
  position: absolute;
  /* border: 1px solid #d4d4d4; */
  width: 120px;
  height: 120px;
}

.img-zoom-result {
  border: 1px solid #d4d4d4;
  position:absolute;
  top:0;
  left:0;
  z-index: 1;
}

a:hover{
  text-decoration: none!important;

}
a{
  cursor: pointer;

}
body {
  font-size: 16px;
  font-family: 'Athena'!important;
}
/*Icons-Sizes*/
.iconSize-14{
  width: 14px;
  height: 14px;
}
.iconSize-xxxs{
  width: 16px;
  height: 16px;
}
.iconSize-xxxs{
  width: 16px;
  height: 16px;
}
.iconSize-xxs{
  width: 20px;
  height: 20px;
}
.iconSize-xs{
  width: 24px;
  height: 24px;
}
.iconSize-sm{
  width: 32px;
  height: 32px;
}
.iconSize-md{
  width: 48px;
  height: 48px;
}
.iconSize-xs--w{
  width: 32px;
  height: 24px;
}
.iconSize-slideMouse{
  width: 2rem;
  height: 1rem;
}

/*Font-Patterns*/
.p-size-1{
  font-family: 'Gilroy'!important;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  font-size: 12px;
  color:#1F1F1F;
}
.p-size-2{
  font-size: 14px;
  font-family: 'Athena';

}
.submit-button2 .p-size-2{
  font-family: 'Gilroy';
}
.p-size--color{
  color:#222222;
}
.p-size--color3{
  color: var(--secondary-color)!important;
}
.p-size--color2{
  color:var(--primary-color)!important;
}
.p-size-3{
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: black;
}
.p-size--b{
  font-weight: bold!important;
}
.h-size-1{
  font-size: 20px;
  line-height: 24px;
  color: var(--primary-color);
}
.h-size-2 {
  font-family: 'Gilroy';
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
}
.h-size-3{
  font-family: 'Gilroy';
  font-size: 16px;
  font-weight: normal;
  line-height: 19px;
}
.h-size-4{
  font-size: 28px;
  line-height: 34px;
  color: var(--primary-color);
}
@media (max-width:992px){
  .h-size-4{
    font-size: 24px;
    
  }
}

.tittle-margin{
  margin-top: 32px;
}
.tittle-margin2{
  margin-top: 80px;
}
@media(max-width:992px){
  .tittle-margin2{
    margin-top: 48px;
  }}
.font-size-1{
  font-family: 'Gilroy';
  font-weight: 400;
  font-size: 10px!important;
  line-height: 12px;
}
.font-size-2{
  font-family: 'Gilroy'!important;
  font-size: 12px;
}

.font-size-3{
  font-size:16px
}

.font--opacity{
  opacity:.5;
}


/*Navbar*/
.shopBorder{
border: 1px solid var(--secondary-color);
padding: 8px 12px;
border-radius: 8px;
}
@media(max-width:320px){
  .shopBorder{
    border: 1px solid var(--secondary-color);
    padding: 8px 3px;
    border-radius: 8px;
    } 
}
.countItemInBasket{
  color: var(--secondary-color);
  font-size: 18px;
  font-family: 'Gilroy';
  margin: 0 4px;

}
.web-navbar{
  padding: 20px 0;
  z-index: 15!important;
}
.web-navbar .nav__item1{
  flex-basis: 15%;
}
 .nav__item4{
  flex-basis: 60%;
  text-align: right;
  position: relative;
}
.nav__item4 .suggestion-result{
  position: absolute;
  z-index: 10 ;
  background-color: white;
  text-align: left;
  width: 100%;
  margin-left: auto;
  padding: 25px 15px 15px 15px;
  top: 44px;
  box-sizing: border-box;
  border: 1px solid var(--primary-color);
  font-family: 'Gilroy'!important;
}

.nav__item4 .suggestion-result::placeholder{
font-family:  'Gilroy';
}
.nav__item4 .suggestion-result ul a {
  display: block;
  border-bottom: 1px solid  #EDE8DE;
  padding: .5rem;
  font-size: 14px;
  font-family: 'Gilroy';
  color: black;
}

.suggestin-all-result{
  font-weight: bold;
  font-size: 14px;
  color: var(--secondary-color);
}
.suggestin-all-result:hover{
  color:  var(--secondary-color);
}

.suggestion-result-title{
  color:  var(--secondary-color);
  font-family: 'Gilroy';
}
  
.web-navbar .nav__item3{
  flex-basis: 20%;
}

.languageSelect{
  border: none;
  background: transparent;
  color: var(--secondary-color);
  cursor: pointer;
  outline: none;
}

.languageSelect:focus{
  border: none;
  background: transparent!important;
}

.languageSelect option:hover{
  background: transparent;
}
.nav__dropdown{
color: var(--secondary-color)!important;
}
.dropdown-toggle::after{
  content: url('../Image/dropdown.svg')!important;
border: none!important;
vertical-align: 0!important;}

.nav__item3 a:last-child{
  margin-right: 0;
}
.nav__item3 a svg{
  color: var(--secondary-color);

}
.menu-shopping{
  cursor: pointer;
  color: var(--secondary-color)!important;
}
.socialMediaTags svg{
  color: var(--primary-color);
 }
 .navMenuSubCategory{
   padding-left: 1.2rem!important;
 }
 .nav__mail:hover{
   color: var(--primary-color);
 }
 .other-pages-logo{
  max-width: 300px;
 }
/*Burger Button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}
 
/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}
 
/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}
 
/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}
 
/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}
 
/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
 width: 538px!important;
 top:0;

}
 
/* General sidebar styles */
.bm-menu {
  background: white;
  padding: 0 1.5em ;
  font-size: 1.15em;

}
 
/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}
 
/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}
 
/* Individual item */
.bm-item {
  display: inline-block;
  outline: none;
  
}

.bm-item .nav__link {
  margin: 0 0 2rem 5rem;
}
.nav__info{
  margin: 0 5rem 2rem 5rem;
}
.bm-item .nav__link a {
  color: black;
  text-decoration: none;

}
.nav__link svg{margin-bottom: 10px;}

/* .bm-item .nav__link:nth-child(6) a{
  color: var(--secondary-color);
} */
.bm-item .nav__sublink{
  padding: .5rem 0;
  cursor: pointer;
  font-family: 'Gilroy'!important;
  font-weight: 500;
}
.bm-item .nav__sublink a{
  text-decoration: none;

  color:black!important;
 
}

.bm-item .nav__link:last-child  {
  border-bottom: 1px solid #F3F0E9;
  padding-bottom: 2rem;
}

.close-sharp{
  cursor: pointer;
  margin-left:4rem ;
  margin-bottom: 1.5rem;
}
.nav__mail{
  font-family: 'Gilroy';
  color: var(--primary-color);
  font-weight: bold;
  font-size: 26px;
}
 
/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  top:0;
}

.menu-burger{
  cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;
}


/*NavBar Popovers*/
.popover{
  min-width: 500px;
  border: var(--third-color);
  -webkit-box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.2);
	-moz-box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.2);
  right: 0;
border-radius: 0!important;
box-shadow: none!important;
}

.popover-delete-product{
  position: absolute;
  right: -20px;
  top: 35%;
  transform: translate(0,-35%);
}
@media(max-width:992px){
  .popover{
    min-width: 250px!important;
    width: 100% !important;
    max-width: unset!important;
    left: 0!important;
    right: unset!important;
    transform: translate3d(0,130px,0)!important;
  }
}
.popover .font-size-2{
  display: block!important;
}
.BasketPopover__Total, .BasketPopover__TotalPrice {
  font-family: 'Gilroy'!important;
}
.BasketPopover__Total{
  color:#C4C4C4;
}
.BasketPopover__TotalPrice {
  color: #000!important;
  font-weight: 500!important;
  font-size: 16px!important;
margin-right: -15px!important;
}

.popover .product-card__newPrice{
font-weight: 500!important;
font-size: 14px!important;
}
.popover .product-card__oldPrice{
  font-size: 12px!important;
  vertical-align: middle;
  margin-top: auto;
  margin-bottom: auto;
  font-family: 'Gilroy';
}
.popover .product-card__size{font-family: 'Gilroy';font-size: 12px;font-weight: 600!important;color: #000!important;opacity: 1!important;margin-top: 12px!important;}
.popover .product-card__size b{font-family: 'Gilroy';font-size: 12px;font-weight: 500!important;color:#C4C4C4;}

.popover .product-card__itemCount{font-family: 'Gilroy';font-size: 12px;font-weight: 600!important;color: #000!important;opacity: 1!important;margin-top: 12px!important;}
.popover .product-card__itemCount b{font-family: 'Gilroy';font-size: 12px;font-weight: 500!important;color:#C4C4C4;}

.popover-header{
  background-color: transparent!important;
  border-bottom: 1px solid #F3F0E9;
}
.popover .arrow {
  display: none!important;
}
.popover{
  top:21px!important;
}
@media(max-width:992px){
  .popover{
    top:9px!important;
  }
}
.popover h6{
  color: var(--primary-color);
  line-height: 24px;
  font-size: 16px;
  padding-left: .5rem;
  margin-bottom: 0;
}
.ProfilPopever__body p{
  margin:0 ;
  padding: 9px 0;
  padding-left: 1.5rem;
}
.ProfilPopever__body p a:hover{
 color: black;
}
.ProfilPopever__body p svg{
  margin-right: 10px;
}
.ProfilPopever__logout{
  border-top: 1px solid  #F3F0E9;

}
.ProfilPopever__like-button{
 color: transparent!important;
}
.ProfilPopever__logout svg,.ProfilPopever__logout a {
  color: #919191;
}
.product-card__stock-btn {
  padding: .5rem 1rem;
  border: 1px solid var(--secondary-color);
  color: var(--secondary-color);
  display: inline-block;
}
.popover .product-card__brand {
  border-bottom: none;
  padding: 0;
}
.popover .product-card__size , .popover .product-card__itemCount {
  color: black;
  opacity: .5;
}
.popover-delete-product{
    padding-right: 3px;
}
.sepetImg{
  width: 65px;
  height: 58px;
}
.sepetImg img{
display: block;
width: 100%;
height: 100%;
object-fit: contain;
}

.BasketPopover__btn{
  display: block;
  padding: 22px;
  background-color: var(--primary-color);
  text-align: center;
  color: white;
  margin-bottom: 1rem;
}
.BasketPopover__btn:hover{
  color: white;
}
.BasketPopover__TotalPrice{
color: var(--secondary-color);
margin-bottom:0 ;
}
.BasketPopover__Result{
margin: 20px 0;
margin-bottom:0 ;

}

.BasketPopever__body{
  max-height: 300px;
  overflow: auto;
  overflow-x: hidden;
} 


/*Header*/
/* .headerSlider{
  background:url('../Image/headerBanner.jpg'); 
  position: relative;
  background-repeat:no-repeat;
  background-position: center center; 
  background-size: cover;
  width: 100vw;
  height: 70vh;
  outline: none;
} */
.headerSlider img{
  /* width: 100%;
  display: block;
  height: 681px;
  object-fit: cover; */
  height: 100%;
}
.headerSlider{
  position: relative;
  height: 681px;
}
.headerSliderShade{
  position: absolute!important;
  top: 0;
  left: 0;
  width: 100%!important;
  height: 100%!important;
  background:linear-gradient(rgba(74, 26, 11,0),rgba(74, 26, 11,0.4))!important;
}


/*Siparis Odeme*/
.siparisOdeme__inputHr .form-group input{
border-bottom: 1px solid #CEC2A6!important;
}
.SiparisOdeme__SignupBtn{
  display: inline-block;
  background-color: var(--secondary-color);
  text-align: center;
color: white;
width: 100%;
padding: 1.2rem 0;
}
.SiparisOdeme__BuyFast{
  display: inline-block;
  background-color: black;
  text-align: center;
  color: white;
  width: 100%;
  padding: 1.2rem 0;
}
.SiparisOdeme__SignupBtn:hover,
 .SiparisOdeme__BuyFast:hover{
  color: white;
}

.SiparisOdeme__section2 {
  border: 2px solid #FAFAFA;
  padding: 96px;
  background:#FAFAFA!important;
}
@media(max-width:992px){

  .SiparisOdeme .LoginModal .form-group{
    width: 100%;
  }
  .SiparisOdeme__section2 {
    padding: 64px;
    margin: 1rem 0;
  }

}



.overlay-categorycard {
  position: absolute;
  background: rgba(0, 0, 0, 0.432);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: .4;
}

.overlay__bannerInspiration{
  position: absolute;
  background: #9C7E59;
  mix-blend-mode: multiply;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: .5;
}

.headerSlider__header{
  position: absolute;
  left: 50%;
  top: 80%;
  transform: translate(-50%, -80%);
  width: 100%!important;
 
}
@media(max-width:992px){
  .headerSlider__header{
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
   
  }
}
.headerSlider__title{
  font-size: 4rem;
  position: relative;
  color: white;
  font-weight: 500;
  font-style: normal;
  line-height: 80px;
}
.headerSlider__border{
display: inline-block;
border-top: 1px solid #F3F0E9;
width: 110px;
text-align: center;
}

@media(max-width:992px){
  /* .headerSlider img{
    width: 100%;
    display: block;
    height: 400px;
    object-fit: cover;
  } */
  .headerSlider{
    height: 400px;
  }
  .headerSlider__title{
    font-size:2rem!important; 
  }

}
@media(max-width:448px){
  /* .headerSlider img{
    width: 100%;
    display: block;
    height: 400px;
    object-fit: cover;
  } */
  .headerSlider{
    height: 300px;
  }
  .headerSlider__title{
    font-size:1rem!important; 
  }
}
@media(max-width:376px){
  /* .headerSlider img{
    width: 100%;
    display: block;
    height: 400px;
    object-fit: cover;
  } */
  .headerSlider{
    height: 280px;
  }
}

  /*Product Card*/
  .category-card{
  width: 100%;
  height: 384px;
  margin: 1rem 0;
  }
  
  .category-card__button{
    position: relative;
    padding: 1rem 0;
    border:1px solid white;
    margin: 0 2rem;
    text-align: center;
    color: white;
    font-size: 22px;
    transition: all .3s ease-in;
    width: 100%;
    margin-top: auto;
    margin-bottom: 1rem;
    line-height: 27px;
  }
  .category-card__button:hover{
    text-decoration: none;
    background-color: white;
    color: black;
  }

  /*Product-Card*/

  .product-card{
  position: relative;
  cursor: pointer;
  outline: none!important;
  user-select: none;
  }
.product-card--pd .product-card{
  padding: 12px; 

}
  .product-card:focus{
    outline: none!important;
    }
    .product-card__image {
      position: relative;
    }
  
    .Fashion .product-card__image img{
      height: 212px;

    }
  .product-card__image img{
    width: 100%;
    /* height: 260px; */
    object-fit: contain;
  }
  .product-card__title{
    font-weight: 400;
    line-height: 19px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp:1;
    -webkit-box-orient: vertical;
    color: black;
  }
 
  .slider__button a {
    width: 100%;
    margin-top: 40px;
    display: inline-block;
    text-transform: uppercase;
    border:1px solid #9E585B;
    color:#9E585B!important;
    padding: 1rem 0;
    text-align: center ;
    transition: all .3s ease-in;
    font-family: 'Gilroy'!important;
    font-weight: 600;
  }
  .slider__button a:hover {
   
    text-decoration: none;
    color:white!important;
    background-color: #9E585B ;
  }

  .product-card__like-button{
    position: absolute;
    top: 10px;
    right: 15px;
    /* padding: 6px 8px; */
    background-color: transparent;
    border-radius: 50%;
    z-index: 1;
  }
  .product-card__like-button.active svg{
   
    color:#000;
    
  }
  .product-card__like-button svg{
    color: transparent;
  } 
   .product-card__discountAmount{
    position: absolute;
    top: 0;
    left: 0;
    padding: .5rem 1rem ;
    background-color: #A63E42;
    font-weight: 700;
    font-family: 'Gilroy'!important;
    color: white;
  } 
  .product-card__body{
    font-family: 'Gilroy'!important;
    font-weight: 400!important;
    margin-top: 1rem;
    text-align: center!important;

  }
  .product-card__brand{
    font-family: 'Gilroy';

    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    padding-bottom:.2rem; 
    margin-bottom: 5px;
  }
  .product-card__brand--hr{
    border-bottom: none!important;

  }
 .product-card__oldPrice{
  line-height: 17px;
  font-size: 14px;
   margin-right: .5rem;
   opacity: .5;
 }
 .product-card__newPrice{
     line-height: 17px;
  font-size: 14px;
font-weight: bold;
 }
 
 .slick-prev {
  background: url('../Image/arrowLeft.svg') center center no-repeat!important;
  padding: 1.2rem!important;
  background-color: #F3F0E9!important;
 z-index: 1;
}
.slick-next::before {
  display: none;
}
 .slick-next {
  background: url('../Image/arrowRight.svg') center center no-repeat!important;
  padding: 1.2rem!important;
  background-color: #F3F0E9!important;
  z-index: 0!important;

 
}  .slick-prev::before {
  display: none;
}
.slick-slide li{
  outline: none;
}


/*Product-Detail*/
.ProductDetailImgSizeSm{
width: 100%;
display: block;
width: 92px!important;
height: 92px;
object-fit: contain;
cursor: pointer;


}
.ProductDetailImgSize{
 width: 100%;
 max-width: 524px;
 max-height: 524px;
 height: auto;
 object-fit: contain;
 position: relative;
 z-index:2;
 cursor: pointer;

}
.ProductDetail-favoriteActive svg {
  color:#000!important;
  border-radius: 50%;
}

.product-card__newPrice--font .product-card__newPrice{
  font-size: 24px;
}
@media (max-width:576px){
  .ProductDetailImgSize{
    width: 100%;
    max-height: 351px;
    height: auto;
   }
}

.product-card__discountAmount--ps{
  margin-left: 4rem;
  position: static;

}
.product-card__description{
  margin-top: 3rem;
text-align: justify;
}
.product-card__addBasket, .product-card__buyNow, .product-card__addFavorite  {
  display: flex;
  align-items: center;
  justify-content: center;
  padding:1.25rem 0 ;
  text-align: center;
  text-transform: uppercase;
  color: white;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  transition: ease-in .3s all;
  cursor: pointer;
}
.loading{
  background-color: grey!important;
  pointer-events: none!important;
  transition: ease-in .3s all;
}

.product-card__addBasket:hover, .product-card__buyNow:hover  {
  color: white;
}
.product-card__addBasket{
background-color: var(--secondary-color);
}.product-card__addBasket svg{
  color: white;
}
.product-card__buyNow{
  padding: 22px 0;
  background-color: var(--primary-color);
  }


.product-card__addFavorite{
  border: 1px solid black;
}
.product-card__addFavorite svg{
  color:transparent;
  
}
/* .product-card__addFavorite:active svg{
  color:var(--secondary-color);
} */
.ProductDetail__productHistory{
  padding:20px 0;
  margin-bottom: 1rem;
}
.ProductDetail__TechnicalInfo{
  background-color:#F3F0E9 ;
  padding: 20px;
}
.productDetail h4{
  padding-bottom:10px;
  text-transform: uppercase;
}
.ProductDetail__DesignerBrand{

      font-family: 'Gilroy';
      font-weight: bold;
      color: var(--third-color);
      font-size: 16px;
line-height: 19px;
}

.ProductDetail__DesignerLink a{
  font-family: 'Gilroy';
  font-weight: 400;
  color: black;
  font-size: 14px;
}
.ProductDetail__DesignerLink a:hover{
  color: black;
}

.ProductDetail__FabricInfo{
  font-weight: bold!important;
}
.ProductDetail__Designer{
  margin-bottom: 1rem;
}

/*Inspiration Banner*/
.InspirationBanner__img img{
  position: relative;
  /* background-repeat:no-repeat!important;
  background-position: center center!important; 
  background-size: cover!important; */
  width: 100%;
  margin: 1rem 0;
  max-height:490px;
  min-height:490px;
  height: 100%;
  object-fit: cover;
  }
  @media (max-width:992px){
    .InspirationBanner__img img{
      max-height:242px;
      min-height:242px;
      height: 100%;
    } 
    .InspirationBanner__text {
      position: relative!important;
      padding: .5rem 0!important;
      margin: 0!important;
    }
    .InspirationBanner__text--w {
      width: 100%!important;
    }
  }
  .InspirationBanner__text {
    position: absolute;
    bottom: 12px;
    left: 12px;
    right: 12px;
    color: black;
    padding: 1.5rem;
    background-color: white;
    margin: 1rem;
  }
  .InspirationBanner__text--w {
    width: 43%;
  }

 

   .InspirationBanner__text h2{
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    font-style: normal;
  }


  /* .InspirationBanner__text--clamp{
    font-family: 'Gilroy';
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp:3;
    -webkit-box-orient: vertical;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: black;
    opacity: .5;
  } */

  /* .BlogandMagazine__text--clamp{
    display: -webkit-box;
    -webkit-line-clamp: 13;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  } */

  /*Footer*/
  .footer{
    background: #000000;
    padding-top: 60px;
  }

.footer__links{
display: inline;
text-align: center;
}

.footer__link{
display: inline-block;
padding: 18px 15px; 

 }
 .footer__link a {
  text-decoration: none;
  color: black;
 }
 .footer__link a:hover {
  color: black;
 }
 .footer__logo{
  margin-top: 2rem;
  margin-bottom: 3.5rem;
 }
 .footer__logoImg{
height: 150px!important;
width: 100%;
object-fit: contain;
 }

 .footer__socialMediaTags a{
  margin-right: 2.25rem;
 
 }
 .footer__socialMediaTags a svg{
  color: var(--third-color);
 }
 .footer__socialMediaTags a:last-child{
  margin-right:0!important;
 }
 .footer__copyright p {
   font-family: 'Basis Grotesque';
   font-size: 14px;
   line-height: 17px;
 }
/*Shop-Page*/
.shopPage__header h2{
  text-transform: uppercase;
  text-align: center;
  color: var(--primary-color);
  font-size: 28px;
  line-height: 34px;
}
.shopPage__header p{
  font-family: 'Gilroy';
  text-align: center;
  line-height: 19px;
  font-weight: 300;
  color: var(--fourth-color);


}
.progress{
  border-radius: 0!important;
  height: .5rem!important;
}
.progress-bar{
  background-color: var(--third-color)!important;
}

/*BreadCrumb*/
.breadcrumb{
  -webkit-border-radius: 0!important;
  -moz-border-radius: 0!important;
  border-radius: 0!important;
  padding: 0!important;
}

.breadcrumb-item a,.breadcrumb-item a:hover{
  font-family: 'Gilroy';
  font-weight: 500;
  color: black;
  font-size: 14px;
}
.breadcrumb-item.active span{
  opacity: .5;
  font-family: 'Gilroy';
  font-weight: 500;
  font-size: 14px;

}

/*Blog & Magazine*/
.BlogAndMagazine__header h4{
  color: var(--primary-color);
  text-align: center;
  text-transform: uppercase;
  padding: 2rem 0;
  font-size: 28px;
  line-height: 34px;
}
/* .BlogandMagazine__text--clamp{
  display: -webkit-box;
  -webkit-line-clamp: 13;
  -webkit-box-orient: vertical;  
  overflow: hidden;
} */
.BlogAndMagazine__header p{
  font-family: 'Gilroy';
  color: var(--fourth-color);
  text-align: center;
  line-height: 19.5px;
  padding-bottom: 2rem;


}

.BlogAndMagazine__Img {
  padding: 1rem 0;
  text-align: center;
}
.BlogAndMagazine__Img img{
  width: 384px;
  height: 458px;
  object-fit: cover;
}
.BlogAndMagazine__Description{
  padding: 2rem;
}

.BlogAndMagazine__toptittle{
  font-family: 'Gilroy';

    color: black;
    opacity: .5;
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: .5rem;
}
.BlogAndMagazine__tittle{
  color: var(--primary-color);
  line-height: 30px;
  font-weight: 600;
font-size: 24px;
}
.BlogAndMagazine__text{
  line-height: 19px;
  font-family: 'Gilroy';
  font-weight: 300;
  color: var(--fourth-color);
}
.BlogAndMagazine__link1{
  font-family: 'Gilroy';
  font-weight: bold;
  color: var(--secondary-color);
  line-height: 19px;

}
.BlogAndMagazine__link2{
  font-family: 'Gilroy';
  font-weight: bold;
  color: var(--primary-color);
  line-height: 19px;

}
.BlogDetail__Banner img {
  width: 100%;
  height: 532px;
  object-fit: contain;
} 

/*KeepInTouch*/
.KeepInTouch{
background-color: var(--primary-color);
padding-top: 3.5rem;
padding-bottom:4.5rem ;
display: flex;
flex-direction: column;
text-align: center;
position: relative;
}
.KeepInTouch_tittle{
text-transform: uppercase;
color: #F3F0E9;
margin-bottom: 0;
z-index: 1;
font-size: 28px;
line-height: 34px;
font-weight: normal;
}

.KeepInTouch__text{
  font-family: 'Gilroy';
  font-weight: 300;
  color: var(--third-color);
  z-index: 1;
  line-height: 19px;
  }
.KeepInTouch__subscribe input{
  border: none;
  border-bottom: 1px solid #f3f0e92f;
  background-color: transparent;
  outline: none;
  color: #F3F0E9;
  font-family: 'Gilroy';
  font-weight: normal;
  line-height: 19px;
  padding-left: 1rem;
  transition: border-bottom .3s ease-in;

 }
 .KeepInTouch__subscribe input:focus{
  border-bottom: 1px solid #f3f0e9dc;
  

 }
 .KeepInTouch__subscribe .input-group-append button{
  font-family: 'Gilroy';
  font-weight: bold;
  line-height: 19px;
 background-color: #123F30;
 color: white;
 border-radius: 0;
 outline: none;
 border: none;
 padding: 18px;

 }
 @media(max-width:576px){
  .KeepInTouch__subscribe .input-group-append button{
  font-size: 14px; 
   padding: 10px;
  
   }
 }
 @media(max-width:320px){
  .KeepInTouch__subscribe .input-group-append button{
  font-size: 10px; 
   padding: 10px;
  
   }
 }
 .KeepInTouch__subscribe input::-webkit-input-placeholder { /* Edge */
  color: white;
}

.KeepInTouch__subscribe input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: white;
}

.KeepInTouch__subscribe input::placeholder {
  color: white;
}
.KeepInTouch__Img img{
position: absolute;
bottom: 0;
left: 50%;
transform: translate(-50%);

}

/*Blog Detail*/

/*Login Modal*/
.LoginModal{
  padding-bottom: 1rem;
}.LoginModal .form-group {
  width: 62%;
}
@media(max-width:576px){
.LoginModal .form-group {
  width:83%;
}
.img-zoom-result {
  display:none !important;
  }
}
.LoginModal .form-group input{
  border: none;
  border-bottom: 1px solid var(--primary-color);
  border-radius: 0;
  width: 100%;
  outline: none;
  padding: .5rem 1rem;
 
}
.LoginModal .form-group input::-webkit-input-placeholder { /* Edge */
  color: var(--third-color);

}

.LoginModal .form-group input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: var(--third-color);
}

.LoginModal .form-group input::placeholder {
  color: var(--third-color);
}
.LoginModal .submit-button{
  background: var(--secondary-color);
  color: white;
  text-transform: uppercase;
  text-align: center;
  border: none;
  outline: none;
  margin-top: 1rem;
  font-family: 'Gilroy';
  font-weight: bold;
  display: block;
  width: 100%;
  /* width: 40%;
  margin: 0 auto; */

}
form input::placeholder{
  font-family: 'Gilroy';

}
.modal-dialog-centered .modal-content{
  z-index: 3;
  padding: 1.5rem;
  border-radius: 0!important;
}
@media(max-width:576px){
  .modal-dialog-centered .modal-content{
  
    padding:0!important;
  }
}
.modal-dialog-centered .modal-header, .modal-dialog-centered .modal-footer{
  border: none!important;
}
.modal-dialog-centered .modal-header{
  padding-bottom: 0;
}
.LoginModal__border{
  border: 2px solid #F3F0E9;
}
.LoginModal__title {
  text-transform: uppercase;
  color: var(--primary-color);
}
.LoginModal__hr p{
  border-bottom: 1px solid  #B29F78;
  width: 100%;

}
.LoginModal__hr{
  padding: 1rem;
}
.modal-dialog-centered .close{
 font-size: 3.5rem;
 color: var(--primary-color);
 opacity: 1;
 outline: none;
}
.LoginModal__links a {
  color: black;
}
.LoginModal__links a:hover {
  color: black;
}
.LoginModal__links a strong {
  color: var(--primary-color);
}
.LoginModal__links .submit-button2{border:1px solid #000!important}
/*Icon Modal*/


 @media(max-width:992px){
    .footer__links ul{
     display: flex;
     flex-direction:column;
   } 
   .footer__socialMediaTags{
     margin-top: 2rem;
     margin-bottom: 3rem;
   }
  
   
   .mobil-navbar{
     background:  #fff;

     box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
     margin-bottom: 20px;
  }
   .web-navbar, .mobil-navbar{
    padding-top: 12px;
    padding-bottom: 18px;
  }

  
  
 } 

 @media(max-width:576px){
  .BlogAndMagazine__Img  img{
    /* min-width: 322px; */
    height: 385px;
   
 }
 .category-card{
   height: 288px;
 }
 .product-card--pd .product-card{
  padding: 7px;
}
}


/*scroll-bar styles*/
#style-4::-webkit-scrollbar-track
{

	background-color: #F5F5F5;
}

#style-4::-webkit-scrollbar
{
	width: 7px;
	background-color: #F5F5F5;
}

#style-4::-webkit-scrollbar-thumb
{
	background-color: var(--third-color);
}


/*iletisim*/
/* 
.iletisim .input-field  {
  font-family: 'Gilroy';
  position: relative;
  line-height: 44px;
}

.iletisim .input-field label,  #messageBoxForIletisim label{
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: -0.0072em;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color:  var(--third-color);
  transition: 0.2s all;
  cursor: text;
  
}


.iletisim .input-field input, #messageBoxForIletisim
 {
  width: 100%;
  border: 0;
  outline: 0;
  padding: 0;
  border-bottom: 1px solid #E5E5E5;
  box-shadow: none;
  color: #111;
}
.iletisim .input-field input:invalid, #messageBoxForIletisim:invalid  {
  outline: 0;
}
.iletisim .input-field input:focus, #messageBoxForIletisim:focus
.iletisim .input-field input:valid,  #messageBoxForIletisim:valid{
  border-color:  #EDE8DE;
  
}
.iletisim .input-field input:focus~label , #messageBoxForIletisim:focus~label
.iletisim .input-field input:valid~label , #messageBoxForIletisim:valid~label
{
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  letter-spacing: -0.0072em;
  top: -24px!important;
  color: var(--third-color);
} */
*:focus{
  outline: none!important;
}
.selectCountry option, .selectCountry .form-control,
.selectProvince option, .selectProvince .form-control,
.selectMonth option, .selectMonth .form-control,
.selectYear option, .selectYear .form-control{
line-height: 44px;
font-family: 'Gilroy';
color: var(--third-color);
border: none;
border-bottom: 1px solid #EDE8DE;
font-size: 16px;
padding-left: 0;
border-radius: 0;
text-align-last: left;
}

.form-control:focus{
border-color: #EDE8DE!important;
outline: none;
box-shadow: none!important;

}
.select_title{
  color: black!important;
}
.sm-label{
  color: var(--third-color);
  font-size: 10px;
}
input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  /* Styling checkbox */
  width: 14px;
  height: 14px;
  background-color: white!important;
  border: 1px solid var(--secondary-color);
}

input[type="checkbox"]:checked {
  background-color: var(--secondary-color)!important;
  border: 1px solid var(--secondary-color);

}

input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  /* Styling radio */
  width: 14px;
  height: 14px;
  background-color: white!important;
  border: 1px solid var(--secondary-color);
  border-radius: 50%;
  -webkit-border-radius: 50%; 
  -moz-border-radius: 50%; 
  border-radius: 50%; 


}

input[type="radio"]:checked {
  background-color: var(--secondary-color)!important;
  border: 1px solid var(--secondary-color);

}

textarea::-webkit-input-placeholder  { /* Chrome/Opera/Safari */
  color:var(--third-color)
}
textarea::-moz-placeholder { /* Chrome/Opera/Safari */
  color:var(--third-color)
}
textarea:-ms-input-placeholder { /* Chrome/Opera/Safari */
  color: var(--third-color)
}
textarea:-moz-placeholder  { /* Chrome/Opera/Safari */
  color: var(--third-color)
}
.iletisim__saveChanges{
  display: inline-block;
padding: 22px 0;
background-color:red ;
width: 100%;
text-align: center;
color: white;
background-color:var(--primary-color) ;

}


.iletisim .input-field  {
  position: relative;
  line-height: 44px;
}

.iletisim .input-field label, #messageBoxForIletisim label{
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: -0.0072em;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: #CEC2A6;
  transition: 0.2s all;
  cursor: text;
  
}


.iletisim .input-field input, #messageBoxForIletisim
 {
  width: 100%;
  border: 0;
  outline: 0;
  padding: 0;
  border-bottom: 1px solid #EDE8DE;
  box-shadow: none;
  color: #111;
}
.iletisim .input-field input:invalid, #messageBoxForIletisim:invalid {
  outline: 0;
}
.iletisim .input-field input:focus,#messageBoxForIletisim:focus,
.iletisim .input-field input:valid, #messageBoxForIletisim:valid{
  border-color: #EDE8DE;
  
}
.iletisim .input-field input:focus~label,#messageBoxForIletisim:focus~label,
.iletisim .input-field input:valid~label,
 #messageBoxForIletisim:valid~label

{
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  letter-spacing: -0.0072em;
  top: -24px;
  color:#CEC2A6;
}
 


.iletisim__saveChanges:hover{
  color: white;
}

.iletisim__changePassword{
  display: inline-block;
  padding: 22px 0;
  background-color:var(--third-color) ;
  width: 100%;
  text-align: center;
  color: white;
}
.iletisim__changePassword:hover{
  color: white;
}

.iletisim__changePassword--c{
  background-color:var(--primary-color) ;

}

/*ProfilDetailCard*/
.ProfilDetailCard{
  padding: 22px 12px ;
  border: 1px solid #FAFAFA;
  background: #FAFAFA;

}
.ProfilDetailCard  .ProfilPopever__header, .SiparisOdeme__title  {
  color: var(--primary-color);
  border-bottom: 1px solid  #EDE8DE!important;
}


 .UserDetail__section3{
  padding: 12px 22px;

}
.UserDetail__section2 h5, .UserDetail__section3 h5{
  font-size: 16px;
  line-height: 19px;
  font-family: 'Gilroy';
  font-weight: 400;
  border-bottom: none!important;
  padding-bottom: .5rem;

}
.UserDetail__section3{
  border-left: 1px solid #EDE8DE;
}
.UserDetail__Profilim,
.UserDetail__Siparislerim,
.UserDetail__KayitliKartlarim,
.UserDetail__OdemeYontemi, .Favorilerim
{
margin: 30px 0;
}
.KayitliAdreslerim{padding-bottom: 50px!important;margin: 30px 0;}
.UserDetail,.ActivationPage__Body,
.DesignerList__Detail, .KayitliKartlarim, .KayitliAdreslerim, .OdemeYontemi,
.ForgotPassowordPage,.sepetim__wrapper, .SiparisOdeme, .shopPage__wrapper{
  min-height: calc(100vh - 383px);
}
.css-h6ird9-singleValue{font-family: 'Gilroy'!important;}
.sepetim__wrapper{
  font-family: 'Gilroy'!important;
}
.css-10d6b8m-placeholder{color: #000!important;font-family: 'Gilroy'!important;}
/*OrderDetail*/
.OrderDetail__OrderCode, .OrderDetail__OrderTotalPrice{
  font-weight: bold;
  font-family: 'Gilroy';
  line-height: 19px;
}

.OrderDetail__OrderStatus1{
  font-family: 'Gilroy';
  color: black;
  opacity: .5;
  font-size: 14px;

}
.OrderDetail__OrderStatus3{
  font-family: 'Gilroy';
  color: var(--third-color);
  font-weight: bold;
  font-size: 14px;

}
.OrderDetail__OrderStatus2{
  font-family: 'Gilroy';
  color: #EC423D;
  font-size: 14px;
}
.cargo-icon{
display: inline-block;
background-color: var(--third-color);
border-radius: 4px;
} .cargo-icon span{
  color: white;
  font-weight: bold;
  font-family: 'Gilroy';

  }
.cargo-icon--m{
  margin: 7px;
}

.OrderDetail__OrderDate{
  font-family: 'Gilroy';
  font-size: 14px;
  line-height: 17px;
  color: black;
  opacity: .5;
}
.OrderDetail__ItemCount{
  font-family: 'Gilroy';
  font-size: 14px;
  line-height: 17px;
  color: black;
  opacity: .5;
  font-weight: normal;
  margin-top: 11px;
}
.OrderDetail__head{
  font-family: 'Gilroy';
  line-height: 19px;
  color: black;
  border-bottom: 1px solid #EDE8DE;
  padding-bottom: 12px ;
  margin-bottom: 0;
  height: 100%;
}
 .UserDetail__sectionSub2{
   background: #FAFAFA;
   margin-bottom: 10px;

}
@media (min-width: 992px){
  .UserDetail__sectionSub2 p{
    margin: 0;
    padding: 18px 0;
  }
 
}
@media (max-width:992px){
  .UserDetail__sectionSub2 {
    position: relative;
   }
  .OrderDetail__ArrowRight{
    position: absolute!important;
  }
   .OrderDetail__ArrowRight p{
  text-align: right ;
  } 
  
}
.UserDetail__sectionSub2 p{
  margin: 0;
  padding: 8px 0;
}

/*AddressCard -- Component*/
.AddressCard{
 padding:  12px;
 border-radius:4px!important;
}
.deliveryTitle{
  font-family: 'Gilroy';
  font-weight: bold;
  font-style: normal;
  line-height: 19px;
  font-size: 16px;
  margin-bottom: .5rem;

}
.receiver__Name,
.receiver__Phone,
.receiver__Address,
.receiver__DistrictAndProvince,
.receiver__CountyAndPostalCode 
{
  font-family: 'Gilroy';
  font-size: 12px;
  line-height: 14px;
  margin-bottom: .5rem;
}
.receiver__Address--opacity{
  opacity: .5;
}
/*PaymentCard -- Component*/
.addressCardTittle, .paymentCardTittle{
  padding-top: 24px;
  padding-bottom: 12px;
}
.paymentCard p {
  font-family: 'Gilroy';
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 8px;
}
.paymentCard--bold{
  font-weight: bold;
}.paymentCard{
  padding-top: 0;
 }

 .UserDetail__sectionSub2--border .UserDetail__sectionSub2 {
  border-bottom: none;
 } 

 .SiparislerimDetay .ArrowLeftBox, .SiparislerimDetay .OrderDetail__ArrowRight {
order:-1;
 }

 .SiparislerimDetay .OrderDetail__ArrowRight p{
  display: none;
}

 @media (min-width:992px){

 }

 .ArrowLeftBox svg {
transform: rotate(-180deg);   }

/*UserDetailItemCard*/
.UserDetailAddBasket{
background-color: var(--secondary-color);
color: white;
padding: 15px 0;
display: inline-block;
text-align: center;
}
.UserDetailItemCard__img{
  object-fit: contain;
}
.UserDetailReturnRequest{
  background-color:#F3F0E9;
  color: var (var(--primary-color));
  padding: 18px 0;
  display: inline-block;
text-align: center;
  }
 
  .UserDetailAddBasket:hover{
    color: white;
  }
  .UserDetailReturnRequest:hover{
    color: black;
  }
  @media (max-width:992px){
    .UserDetail img{
      width: 120px;
      height: 110px;

    }
    .UserDetail  .product-card__title{
      font-size:12px;
      margin-bottom: .25rem;
    }
    .UserDetail .product-card__brand,
    .UserDetail  .product-card__size,
    .UserDetail  .product-card__count,
    .UserDetail  .product-card__price

    {
      font-size: 10px;
    }

    
  }
  /*Favorilerim*/
  .UserDetailBuyNow{
    background-color: var(--primary-color);
    padding: 18px 0;
    display: inline-block;
    text-align: center;
    color: white;
  }.UserDetailBuyNow:hover{
    color: white;
  }
  select{
    text-align-last: center;
  }

  /*Kayırlı Adresler */
  
  .kayitlikartlar{
    width: 90%;
  } 
  .addAddressBtn{
    display: inline-block;
    padding: 18px 0;
    color: white;
    background-color: var(--secondary-color);
    width: 100%;
    cursor: pointer;
  }
  .addAddressBtnCancel{
background-color: white;
color:black ;
opacity: .5;
display: inline-block;
padding: 18px 0;
width: 100%;
cursor: pointer;


  }
  .addAddressBtn:hover{
color: white;
  }
  @media (max-width: 992px){
    .kayitliadresler,
    .kayitlikartlar{
      width: 85%;
     

    }
  }
/*Kayıtlı Kredi Kartlarım*/
.CreditCards , .AddressCard{
  border:1px solid #E5E5E5; 
  border-radius: 12px;
  -webkit-border-radius: 12px; 
  -moz-border-radius: 12px;
  padding: 12px; 
}
  .creditCard__Img img{
    width: 115px;
    height: 68px;
  }

  /*Sepetim*/

.Sepetim h5, .AdresBilgisi h5, .OdemeYontemi h5{
  font-family: 'Gilroy';
  font-size: 16px;
  line-height: 19px;
  padding-top:24px;
  padding-bottom:12px;
}
.sepetim__mt .selectProvince .form-group{
  margin-bottom:0!important;
  margin-left: auto!important;
  margin-right: auto!important;
  width: 100%!important;
}
.sepetim__mt .selectProvince .form-group .css-i3b6il-IndicatorsContainer{
display: none!important;
}
.sepetim__mt .selectProvince .form-group .css-cojlzs-control, .sepetim__mt .selectProvince .form-group .css-qrznxx-control{
border:1px solid #DEDEDE!important;
padding-top: 8px;
padding-bottom: 8px;
margin-top: 0!important;

}
.sepetim__mt .product-card__body{
  text-align: left!important;
}

.sepetim__mt .selectProvince .form-group .css-nye3go-container{width: 100%!important;}
.sepetim__mt{
  margin-top: 32px;
}
.OrderSummary{
  padding: 0 12px;
  padding-bottom: 4px;
  border: 1px solid #EDE8DE;
}
.OrderSummary__title{
  color: var(--primary-color);
}

.OrderSummary__btn{
  padding:22px 0 ;
  display: block;
  text-align: center;
  cursor: pointer;
}
.OrderSummary__btn--btn1{
  background-color: #EDE8DE;
  color: white;
  margin-bottom:.5rem;
  margin-top: 1rem;
}
.OrderSummary__btn--btn1:hover{
  color: white;
}
.OrderSummary__btn--btn2,.OrderSummary__btn--btn2:hover{
  color: black;
  background-color: #FAFAFA!important;

}
.OrderSummary__btn--btn2{
  color: black;
}
.OrderSummary p{
 padding: .5rem;
 margin: 0;
}
.OrderSummary .GeneralTotalPrice{
  margin-top: 12px;
}
.OrderSummary {
  background: #FAFAFA!important;
  border:1px solid #FAFAFA!important
}
/*Adres Bilgisi*/
.teslimatAdresi{
  border-right:  1px solid #EDE8DE;
}
.AdresBilgi__subtitle h4{
  border-bottom:  1px solid #EDE8DE;
  padding-top :21px;
  padding-bottom: 10px;
}
.AdresBilgi__subtitle{
  margin: 2.5rem 0;

}.AdresBilgisi .section1{
  margin-top: 2rem;
}
.AdresBilgisi .kayitliadresler{
  position: relative;
}
.AdresBilgisi .kayitliadresler .addressCardDropdown {
  position: absolute;
  right: 0;
  top:0;
  height: 100%;
  border-radius: 0;
  cursor: pointer;

  background-color: #DEDEDE;
  padding: 0 .5rem;
}
.addressCardDropdownItems{
height: 300px;
overflow-y: scroll;
}
.addressCardDropdown svg{
  color: red;
}

.CCvPopup{
  border-bottom: 1px solid #EDE8DE ;
}

@media(max-width:992px){
  #popover-contained4{
    min-width: 200px;
  }
}
@media(min-width:1200px){
  .IbanTextArea{
    border: none;
    min-width: 260px;
    height: 20px;
    resize: none;
    font-size: 16px;
    font-family: 'Gilroy';
    outline:none;
  
  }
}

.IbanTextArea{
  border: none;
  resize: none;
  font-size: 16px;
  font-family: 'Gilroy';
  outline:none;
}

/*OdemeYontemi*/
.RemittanceInformation{
  color:#948C8C;
}
.OdemeYontemi__options{
  margin-top: 2.5rem;
}
.RemittanceInformation__strong{
  opacity: 1!important;
  color: var(--primary-color);
  font-weight: bold;
}
.sozlesmelink{
  text-decoration: underline;
  color: black;
}
.sozlesmelink:hover{
  color: black;
  font-weight: 500;

}
.bank__img{
  max-width: 300px;
  height: auto;
  object-fit: contain;
}
/*Navbar Menu Responsive*/
@media (max-width:992px){ 
  .bm-menu{
    padding-left: 0;
}
.nav__link{
  margin:0 5rem 2rem 2rem!important;
}
.close-sharp{
  margin-left: 1rem;
}
.mobil-r svg{
margin-left: 7rem;}
.nav__info{
  margin:0 5rem 2rem 2rem!important;
}
.socialMediaTags a {
  margin-right:1.8rem;
}
}
@media (max-width:280px){
  .socialMediaTags a {
    margin-right:1rem;
  }
}

.slick-dots{
  display: block!important;
  position: static!important;
}
.slick-dots li button:before{
  font-size: 8px!important;
  border:2px solid #fff!important;
  height: 8px!important;
  width: 8px!important;
  border-radius: 50%;
  content: '' !important;
  opacity: 1 !important;
}

.slick-dots li.slick-active button:before{
  background: #fff!important;
  content: '' !important;
}  


.product-card .slick-next{
  right: -25px!important;
}

.product-card .slick-prev{
 
  left :-25px!important;
}


.slick-next{
  right: 24px!important;
}
.slick-prev{
left:24px!important;
}


/*User Detail Card Mobil*/
.UserDetailCardMobil button{
  width: 100%;
}
.UserDetailCardMobil.dropdown .dropdown-toggle a:hover{
  color: black;
}
.UserDetailCardMobil.show .dropdown-menu .dropdown-item svg{
 margin-right: 8px;
}
.UserDetailCardMobil.show .dropdown-menu .dropdown-item a:hover{
  color: black;

 }
.UserDetailCardMobil .dropdown-menu{
  width: 100%;
}
.ProfilPopever__body p{font-weight: 500!important;}
.bg--secondary{
  position: relative;
}
.bg--secondary a{
  color: #A63E42!important;
}
.bg--secondary svg{
  color: transparent!important;
}
.bg--secondary a::after{
  content: url('../Image/chevSVG.svg');
  position:absolute;
  right: 0;
  top: 50%;
  transform: translate(-50%,-50%);
  color: #A63E42!important;
  vertical-align: middle;
  font-weight: 600;
}

.UserDetailCardMobil #dropdown-item-button{
  background-color: #DEDEDE;
}.UserDetailCardMobil .dropdown-item.active{
  background-color: #DEDEDE;
}
.UserDetailCardMobil {
  margin-bottom: 10px;
 
}
.UserDetailCardMobil .dropdown-item p{
  margin-bottom: 0;
  padding:10px 0 ;
  outline: none;
}

#dropdown-item-button {
  padding: 15px 0;
  outline: none;
  border: none;
}
.UserDetailCardMobil.dropdown #dropdown-item-button {
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;

}
.UserDetailCardMobil.dropdown #dropdown-item-button p{
  margin: 0;
}
.UserDetailCardMobil.dropdown #dropdown-item-button p svg{
  margin-right:10px ;
}

#dropdown-item-button:focus {
  box-shadow: none;
  outline: none;
}

.dropdown-item:active{
  background-color: transparent!important;
}
button:focus{
  outline: none!important;
}

.paymentCard img{
  width: auto!important;
  height: auto!important;
}

/* .productDetail .slick-prev,.productDetail .slick-next{
  top: auto;
  transform: rotate(90deg);
  padding: 0!important;

} */

.slick-track{
  max-height: 525px;
}
@media(min-width:576px){
  .productDetail .col-sm-2 .slick-prev, 
  .productDetail .col-sm-2 .slick-next{
    top: auto;
    padding: 0!important;
    transform:  rotate(90deg);
    position: static;
    margin: 0 auto;
   
  } 
 
}


/* .center{
 background: ;
} */

.productDetail .col-sm-2 .slick-current{
  opacity: .5;
} 


.searchbar {
  width: 0%;
  border: none;
  border-bottom: 1px solid #000;
 margin-left: auto;
 transition: width .4s ease-in;
 font-family: 'Gilroy';
}
@media(min-width:992px){
  .searchbar {
   
   margin-top:17px!important ;
   margin-bottom:17px!important ;
  }
}
.searchbar--w {
  width: 100%;
  /* position: relative; */

}
.searchbar:focus {
 outline: none;

}


#multiselectContainerReact {

  border: none;
  width: 100%;
  background-color: transparent;
}
#multiselectContainerReact div{
  border: none;
  border-bottom: 1px solid var(--secondary-color);
  border-radius: 0;
}
.optionContainer{
  background-color: transparent;
}
.chip{
  color: white;
  background-color:var(--third-color);
}

.css-xb97g8{
  display: none!important;
}


/*Uye Ol*/
@media (min-width:992px){
  .SignUp{
    margin-top: 90px;
    margin-bottom: 80px;
    }
}

.conditions a{
  text-decoration: underline!important;
}
.SignUpImg img{
  width: 100%;
  max-width: 513px;
  max-height: 628px;
  object-fit: cover;
}

.signUp__title{
  border-bottom: 1px solid #EDE8DE;
  padding-top: 21px ;
  padding-bottom: 10px;
}
/*Designers*/

.Designers__header{
  background-color:#F3F0E9;
}
.Designers__bannerImg Img{
  width: 100%;
  max-height: 209px;
  object-fit: cover;
}
.Designers__logoImg img{
  width: 100%;
  max-width: 154px;
  max-height: 154px;
  object-fit: cover;
  border-radius: 50%;
}
.Designers__socialMediaTags{
  text-align: center;
  margin-top: 42px;
}

.Designer__Title{
  padding-top: 26px;
  padding-bottom: 12px;
  border-bottom: 1px solid #EDE8DE;
}
.Designers__header{
 margin: 25px 0;
}
.Designer__brand{
  text-decoration: underline;
}

/*ARTS AND WORKSHOP*/
/* .ArtsAndWorkshop__text--clamp{
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;  
  overflow: hidden;
} */
.ArtsAndWorkshop__topBtns a{
  display: inline-block;
padding: 12px 24px;
margin-right: 24px;
border-bottom: 1px solid #EDE8DE;
}
.ArtsAndWorkshop__topBtns a:hover{
  color: black;
  }
.ArtsAndWorkshop__topBtns a.active{
background-color: #F3F0E9;
  }
.ArtsAndWorkshopCard__img{
  text-align: center;
}
.ArtsAndWorkshopCard__img img{
   max-height:320px;
  width: 100%;
  height: 100%;  
  object-fit: contain;
} 

.ArtsAndWorkshopCard__title{
  font-family: 'BegumW03';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
}
.ArtsAndWorkshopCard__date {
  color: var(--secondary-color);
}
.ArtsAndWorkshopCard__text{
  font-weight: 300;
  font-size: 14px;
  opacity: .5;
  font-family: 'Gilroy';
  line-height: 17px;

}
.loadMore, .countOfShownItems{
  margin-top: 56px;
  margin-bottom: 80px;
}
@media (max-width:992px){
  .loadMore{
    margin-bottom: 0;
    text-align: center;
  }
  .countOfShownItems{
  margin-top: 32px!important;
  text-align: center;

  }
}

.divAboutRow{
  position: relative;
  margin: 0!important;
}
/*about*/
.About{
  padding-bottom:40px;

}
.About h2{
margin-bottom:40px;
}
.About img{
  max-height:412px;
  object-fit: cover;
  width: 100%;
 
}
.divMarginAbout{margin-bottom: 2rem!important;}
.divMarginAbout:hover .divShade{display: none;}

.About__headerBtn{
  display: block;
  text-align: center;
  padding: 22px 0;
  width: 100%;
color: var(--primary-color);
border: 1px solid  var(--primary-color);
transition: all .3s ease-in;
margin-bottom: 4.5rem;
}

@media (max-width:576px){
  .About img{
    max-height:136px;
  
  }
  .About__headerBtn{
    margin-bottom: 4.5rem;
  }
  
.ApplyDesigner__applyForm {
  margin-bottom: 48px;
}
}
.About__headerBtn:hover{
color: white;
background-color: var(--primary-color);
}


/*APPLY DESIGNER*/
.ApplyDesigner__btn a{
 display: block;
 text-align: center;
 padding: 22px;
 background-color: var(--primary-color);
 color: white!important;
}

.ApplyDesigner__img img{
height: 628px;
width:513px;
object-fit: cover;
}

.ApplyDesigner__applyForm {
  margin-bottom: 72px;
}

/*Date Time Picker*/
.react-datepicker__input-container,.react-datepicker-wrapper{
display: block!important;
}
.react-datepicker__input-container input{
width: 100%;
  }
  .react-datepicker__input-container input::placeholder{
color: #CEC2A6;}
.react-datepicker__input-container input:-ms-input-placeholder{
  color: #CEC2A6;}
  .react-datepicker__input-container input::-ms-input-placeholder{
    color: #CEC2A6;}
  .react-datepicker__month-container{
    display: block;
    width: 100%;
  }
  /*Input Type File*/
  input[type="file"] {
    display: none;
}

.cvChooseafile{
  border-bottom: #CEC2A6!important;
  padding-left: 0!important;}
.cvChooseafile::placeholder{
  color: #CEC2A6!important;
}
.cvChooseafile:-ms-input-placeholder{
  color: #CEC2A6!important;
}
.cvChooseafile::-ms-input-placeholder{
  color: #CEC2A6!important;
}
.cvChooseafile--border{
  border-bottom: 1px solid #EDE8DE!important;
  
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}
  /*Navbar*/
.nav__item2{
  min-width:171px ;
}
@media(max-width:992px){
  .nav__item2{
    padding: 0 1rem;
  }
}
 
  .lg-img img{
  position: absolute;
  bottom: 0;
  bottom: 0%;
  left: 50%;
  transform: translateX(-55%);
  width: 727px;
  transition: width .5s linear;
  transition: height .5s linear;

  height: 119px;
  }

  .smaller1 img{
    height: 60px;
    width: 194px;
    transition: width .5s linear;
    transition: height .5s linear;

  }



/*DESIGNERLIST*/
.all-designer-list{
column-count: 4;
column-gap: 30px;
}

@media(min-width:992px){
  body{
    margin-top: 170px!important;
  }
  .web-navbar--home{
    position: relative;
    transition: all .6s;  
  }
  .top-links{
    padding-top:10rem;
    transition: all .8s ease;
  }
  .top-links a{
    text-transform: uppercase;
    border-bottom:0;
    transition: all .4s ease;
    color: var(--primary-color);



  }
  .top-links a:hover{
    color: var(--primary-color);

    font-size: 18px;
    transition: all .4s ease;
  border-bottom: 3px solid var(--primary-color);
  }
   .web-navbar{
    position: fixed;
    top: 0;
    background-color: white;
    z-index: 2;
  } 

  .web-navbar.smaller .top-links{
    transition: all .8s ease;
    padding-top: 1rem;

  }

  /* .Home{
    margin-top: 230px;
  } */
  .img-fluid.matrix3{
    transform: matrix(.5, 0, 0, .5, 0, 0); 
    height: 60px;
  
    /* transform: scale(1); */
    transition: all .8s;

  }
  
  .img-fluid.matrix1{
    transform: matrix(1, 0, 0, 1, 0, 100 ); 
    /* transform: scale(2.7); */
    transition: all .8s;
    height: 94px;
    
  }
  
}
.DesignerList__filterBtns{
  margin-bottom: 20px;
}
.DesignerList__filterBtns a{
  display: inline-block;
padding: 12px 24px;
border: 1px solid #FAFAFA;
color: black;
margin-right: 12px ;
font-size: 12px;
font-family: 'Gilroy';
font-weight: 500;
}
.DesignerList__filterBtns a:hover{
color: black;
}

@media(max-width:992px){

  .DesignerList__filterBtns a{
    margin: 4px;

  }
}
.DesignerList__filterBtns a.active{
background-color: #FAFAFA;
}
.all-designer-list span {
  font-family: 'Gilroy';
font-size: 24px;
font-weight: bold;
}
.all-designer-list .designer__link{
  color: black;
}

@media (max-width: 992px){
  .all-designer-list{
    column-count: 3;
    column-gap: 30px;
    }
}
@media (max-width: 576px){
  .all-designer-list{
    column-count: 2;
    column-gap: 30px;
    }
}

/*FILTERS*/
/*mobil filters*/
.FiltersMobileBtns{
  display: flex!important;
  justify-content: space-between;
  border-bottom: 1px solid #EDE8DE; 
  align-items: baseline;
}
.FiltersMobileBtns__title{
  font-family: 'Gilroy';
  padding-top: 24px;
  padding-bottom: 12px;
  padding-left: 8px;
  display: block;
  width: 100%;
  margin-bottom: 0;
  cursor: default;

}
.FiltersMobileContainer{
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: white;
  z-index: 99;
  position: fixed;
  padding: 12px;
}
.FiltersMobileBtns__title--sub{
  border-bottom: 1px solid #EDE8DE; 
  color: black;
  display: inline-block;
  width: 100%;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 99;
  cursor: default;

}
.FiltersMobileBtns__title--sub svg{
transform: rotate(90deg);
margin-right: 12px;
}
.customCheckbox{
  margin-left: 5px;
  width: 14px;
  height: 14px;
  border:1px solid #B29F78;
  border-radius: 2px;
}
.CategorySubItemName{
  font-family:'Gilroy' ;
  color: black;
  margin-left:8px;
  cursor: default;
}
.CategorySubItem{
  padding: 8px 0;
  display: flex!important;
  align-items: center;
  cursor: default;

}

.CategorySubBtns{
  display: block;
  position: fixed;
  bottom: 12px;
  left: 12px;
  right: 12px;
}
.CategorySubBtn{
  display: block;
  padding: 22px 0;
  width: 100%;
background-color: var(--primary-color);
font-weight: bold;
font-size: 14px;
font-family: 'Gilroy';
text-transform: uppercase;
}


.CategorySub{
  height: calc(100% - 130px);
  overflow: auto;
}
.MobileCategoryLabel{
  font-size: 10px;
  font-family: 'Gilroy';
  color: var(--third-color);
  text-transform: uppercase;
  margin-bottom: 0;
}
.MobileCategoryChecked{
  font-family: 'Gilroy';
  display: inline;
  padding-bottom: 12px;
  padding-top: 8px;
}
.borderBlock{
 border-bottom: 1px solid #EDE8DE;
 display: block;
 margin-top: 16px;
 padding-left: 8px;
 padding-bottom: 7px;

} 
.MobileCategoryChecked::after{
content: ", ";
} 
.MobileCategoryChecked:last-child::after{
  content: " ";
  }

  .MobileFiltrelerDropdown
  { border-bottom: 1px solid #EDE8DE;
  }

/*------*/

.WebApplyButton{
  font-size: 12px;
  font-family: 'Gilroy';
  color: rgb(170, 122, 86);
  border:1px solid rgb(170, 122, 86);
  font-weight: 700;
  display: inline-block;
  text-align: right;
  margin: .6rem;
  padding: .3rem 1rem;
}
.WebApplyButton:hover{
  background-color: rgb(170, 122, 86);
  color:white;
}

.productFilters{
  font-family: 'Gilroy';
}
.productFilters__priceRange{
  color: #808080;
margin: 0;
display: block;
border-bottom: 1px solid #EDE8DE;
padding-top: 8px;
padding-bottom: 5px;
padding-left: 8px;
width: 100%;
font-family: 'Gilroy';

}



.productFilters__priceRange:hover{
border-bottom: 1px solid #808080;
color: #808080;

}


.input-range{
margin-top:1rem;
}
.input-range__slider{
  background: #909090!important;

border: 1px solid  #F3F0E9!important;}
.input-range__track--active {
  background: var(--secondary-color)!important;
}

.categoryFilter__labelFont{
  font-family: 'Gilroy';
   display: block;
  font-size: 12px;
  color:#C4C4C4;
  font-weight: 600;
  text-transform: uppercase;
  padding-top:25px!important;
  padding-bottom:25px!important;
  margin-left: -15px!important;
}
#react-select-3-input:focus{
  border: none!important;
  outline: none!important;
}

#messageBoxForIletisim{
  resize: none;
}

 .iletisim--pd{
  padding: 0 4rem;
}



.CategorySliderCard img{
  max-height:182px;
  min-height:182px;
  min-width: 199px;
  width: 100%;
  height: 100%;  
  object-fit: cover;
  padding: .5rem;
}@media(max-width:576px){
  .CategorySliderCard img{
    max-height:156px;
    min-height:156px;
    min-width: 172px;
    width: 100%;
    height: 100%;  
    object-fit: cover;
    padding: .5rem;
  }
}
.CategorySliderCard a{
  outline: none;
}
.DatePicker {
  color: #CEC2A6!important;
}
.DatePickerSelected {
  color: 'black'!important;
}
.loadingImage {
  width: 100%;
  height: 100%;
  animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #F6F6F6;
    background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
    background-size: 800px 104px;
    min-height: 300px;
    position: relative;
}

.loadingText {
  width: 100%;
  height: 100%;
  animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #F6F6F6;
    background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
    background-size: 800px 104px;

    position: relative;
}

@keyframes placeHolderShimmer{
  0%{
      background-position: -468px 0
  }
  100%{
      background-position: 468px 0
  }
}
.module {
  width: 100%;
  overflow: hidden;
}

/*ArtsAndWorkshop*/
.ArtsAndWorkshop__text--clamp {
      position: relative;
      height: 3.6em; /* exactly three lines */
      overflow: hidden;

    }
.ArtsAndWorkshop__text--clamp:after {
      content: "";
      text-align: right;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 70%;
      height: 1.2em;
      /* background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%); */
 }
 @supports (-webkit-line-clamp: 3) {
  .ArtsAndWorkshop__text--clamp {
      display: -webkit-box;
      -webkit-line-clamp:3;
      -webkit-box-orient: vertical;  
      max-height:115px; 
      height: auto;
  }
  .ArtsAndWorkshop__text--clamp:after {
      display: none;
  }
}

.BlogandMagazine__text--clamp {
  position: relative;
  overflow: hidden;

  height:6em; /* exactly three lines */
}
.BlogandMagazine__text--clamp:after {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 70%;
  height: 1.2em;
  /* background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%); */
}

@supports (-webkit-line-clamp: 13) {
.BlogandMagazine__text--clamp {
  display: -webkit-box;
  -webkit-line-clamp:13;
  -webkit-box-orient: vertical;  
  max-height:115px; 
  height: auto;
  overflow: hidden;

}
.BlogandMagazine__text--clamp:after {
  display: none;
}
}

.InspirationBanner__text-font{
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  color: black;
  opacity: .5;
  font-family: 'Gilroy';
}
.InspirationBanner__text--clamp {
  position: relative;
  height: 4em; /* exactly three lines */
  overflow: hidden;

}
.InspirationBanner__text--clamp:after {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 1.2em;
  /* background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%); */
}

@supports (-webkit-line-clamp: 3) {
.InspirationBanner__text--clamp {
  display: -webkit-box;
  -webkit-line-clamp:3;
  -webkit-box-orient: vertical;  
  max-height:115px; 
  height: auto;
  overflow: hidden;
}
.InspirationBanner__text--clamp:after {
  display: none;
  
}
}  
.fullscreen-overlay{
  position: fixed;
left:0;
top: 0;
width: 100%;
height: 100%;
background-color: rgb(0, 0, 0,  42%);
z-index: 90;
opacity: .5;
}
.favoriteoverlay{

   display: flex;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.3);
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
  
}
.modal-open{
  padding-right: 0;
}

/* .InspirationBanner__text--clamp{
  font-family: 'Gilroy';
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp:3;
  -webkit-box-orient: vertical;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  color: black;
  opacity: .5;
} */



/* input[type="date"] {
  padding: 10px;
  color: #CEC2A6!important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: black!important;
  background: #CEC2A6;
  content: '\f073';
}

input[type="date"]:before {
 
  background: none;
  display: block;

 
} */

/*İş Birliklerimiz*/
.IsBirlikleriImgCard img{
  width: 100%;
  height: 490px;
  object-fit: cover;
  padding: 1rem;
   position: relative;
}
 .IsBirlikleriImgCard__body{
  position: absolute;
  top: 20%;
  left: 40px;

}

.IsBirlikleriImgCard__title{
  font-size:52px;
  color: white;
  line-height:52px;
  font-family: 'Gilroy';
  font-weight: bold;
  max-width: 200px;  


}

.IsBirlikleriImgCard__sub-title{
  font-size:52px;
  color: white;
  line-height:52px;
  font-family: 'Gilroy';
  font-weight: bold;
  max-width: 275px;  
  margin-top: 4rem;
  

}
.sub-title--wrapper{
position: absolute;
bottom: 10px;
}

@media(max-width:576px){
  .IsBirlikleriImgCard img{
  
    height: 390px;
    padding: .5rem;

  }
  .IsBirlikleriImgCard__title{
    font-size:38px;
    color: white;
    line-height:38px;
    font-family: 'Gilroy';
    font-weight: bold;
    max-width: 150px;  
  
  
  }
  
  .IsBirlikleriImgCard__sub-title{
    font-size:38px;
    color: white;
    line-height:38px;
    font-family: 'Gilroy';
    font-weight: bold;
    max-width: 200px;  
    margin-top: 2rem;
    
  
  }

}
.MusteriHizmetleri__title{

font-family: 'BegumW03-Regular';
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 20px;
padding: 8px 0;
margin-bottom: 0;
}
.MusteriHizmetleri__text{
  font-family: 'Gilroy';
  font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 19px;
color: var(--primary-color);
padding: 18px 0;
margin-bottom: 0;
}
.MusteriHizmetleri__text--font{

font-size: 32px;
line-height: 38px;
}

@media(max-width:576px){
  .MusteriHizmetleri__text--font{

    font-size: 20px;
    line-height: 38px;
    }
}

.MusteriHizmetleri__textarea textarea{
  border: none;
  border-bottom: 1px solid #EDE8DE;
resize: none;
}

.MusteriHizmetleri__textarea textarea::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  padding:1rem .5rem;
  font-size: 17px;
line-height: 19px;
font-family: ' Gilroy';
color: #CEC2A6;

}
.MusteriHizmetleri__textarea textarea::-moz-placeholder { /* Firefox 19+ */
  padding:1rem .5rem;
  font-size: 17px;
line-height: 19px;
font-family: ' Gilroy';
color: #CEC2A6;
}
.MusteriHizmetleri__textarea textarea:-ms-input-placeholder { /* IE 10+ */
  padding:1rem .5rem;
  font-size: 17px;
line-height: 19px;
font-family: ' Gilroy';
color: #CEC2A6;
}
.MusteriHizmetleri__textarea textarea:-moz-placeholder { /* Firefox 18- */
  padding:1rem .5rem;
  font-size: 17px;
line-height: 19px;
font-family: ' Gilroy';
color: #CEC2A6;
}
.MusteriHizmetleri__body label{
  padding:1rem .5rem;
  font-size: 17px;
line-height: 19px;
font-family: ' Gilroy';
color: #CEC2A6;
}
.MusteriHizmetleri__mainTitle{
text-transform: uppercase;
text-align: center;
font-family: ' Gilroy';
padding-top: 48px;
}

.MusteriHizmetleri__border{
  border: 2px solid #F3F0E9;
  
}
.Toastify__toast--default {
  background-color: #10372A!important;
  color:'#FFF'!important;
}
.Toastify__toast-body{
color: #F3F0E9!important;}

.sozlesme .ProfilPopever__body p {
padding: 12px;
}
.sozlesme .ProfilPopever__body p a{
  font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 14px;  
font-family: 'Gilroy';
text-transform: uppercase;
color: black;
padding: 18px 0;
}
.ft-main-link{
  margin: 1rem 0;
}
.ft-sub-link{
  margin: .5rem 1rem;
}

.ft-link{
  color: black;
font-weight: 600;
line-height: 19px;
}
.ft-link-main{
  margin-top: 1rem;
  color: black;
  font-weight: 600;

}

.ft-link-sub{
  font-weight: 600;
  color: black;
}

.rejectReason{
  margin-top: 1rem;
  font-family: 'Gilroy';
  font-size: 14px;
  border: 1px solid #dc354634!important;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding: .25rem 1rem;
}
@media(max-width:576px){
  .rejectReason{
    
    padding: .25rem 0;
  }
}

.divInstallationCol{border: 1px solid #dedede;height: 285px; overflow-y:auto;}
.divInstallationCol .row{border-bottom: 2px solid #fff;}
.divInstallationCol .col-3{text-align: center;}
.divInstallationCol .col-3 input{margin-left: auto; margin-right: auto; margin-top: 6px;margin-bottom: 6px;}
.divInstallationCol .col-3 .divColTitle{font-family: 'Gilroy'; font-weight: 800;margin-top: 20px;margin-bottom: 20px;}
.divInstallationCol .col-3 .divColItem{font-family: 'Gilroy'; font-weight: 500;margin-top: 6px;margin-bottom: 6px;}
.divInstallationCol .col-3:nth-child(2n+1){background:#dedede;}

.divGiftWrap{padding-left:15px; padding-right: 15px;font-family: 'Gilroy';}

.product-card__image__nostock__overlay{position: absolute; text-align: center; background:red;top: 50%; left: 50%;transform: translate(-50%,-50%);  width: 85%; background: #000; opacity: 0.6;}
.product-card__image__nostock__overlay .divNoStockTitle{font-family: 'Gilroy'; color: #fff;  font-size: 24px;}

@media(max-width:992px){
  .product-card__image__nostock__overlay .divNoStockTitle{ font-size: 16px;}
}

.btnReturn{ font-family: 'Gilroy'; border: 1px solid rgb(170, 122, 86)!important;border-radius: 0!important;background: #fff!important; color: rgb(170, 122, 86)!important;transition: .5s ease!important;}
.btnReturn:hover{background: rgb(170, 122, 86)!important; color: #fff!important;}

.divIadeForm{margin-top: 20px; padding-left: 20px; padding-right: 40px;}
.divIadeForm label{font-family: 'Gilroy'; }
.divIadeForm .form-control{border-radius: 0!important;font-family: 'Gilroy'; }

.divRefundForm label{font-family: 'Gilroy'; }
.divRefundForm .form-control{font-family: 'Gilroy'; }
.divRefundForm textarea{border-radius: 0!important;border-top: none!important; border-left: none!important; border-right: none!important; border-bottom: 1px solid #000;}

.css-1aujs45-indicatorContainer{color: #000!important;}
.css-6q0nyr-Svg{color: #646464!important;}
.IndexSlider{position: relative!important;}
.IndexSlider .slick-dots{position: absolute!important; bottom: 12px!important;}
.IndexSlider .slick-arrow{display: none!important;}
.sliderLogoClass{position: absolute;top: 50%;left: 50%; transform: translate(-50%,-50%)!important;width: 315px!important;height: 95px!important;z-index: 2;}

.divFooterBigItem{color: #fff;font-size: 18px;font-weight: 500;margin-bottom: 12px;font-family: 'Gilroy'!important;}
.divFooterItem,.divFooterItem:hover{color: #fff;font-size: 12px;font-weight: 500;margin-bottom: 12px;font-family: 'Gilroy'!important;}
.divFooterBrownItem{color: #E0CBA8;font-size: 12px;font-weight: 500;margin-bottom: 12px;font-family: 'Gilroy'!important;}

.divFooterWhiteLine{width: 100%!important;height:2px;background:#fff;margin-bottom: 40px;margin-top: 40px;}
.divCopyCol{text-align: left;}
.divDesignCol{text-align: right;cursor: pointer; display: flex;}
.divDesignCol .divFooterItem{margin-left: auto!important; margin-right: 10px;}
.divDesignCol img{margin-bottom: 10px!important;}
.divFooterSocialLinks{width: 75%;margin-top: 20px; }
.divFooterSocialLinks a{color: #fff; }


@media(max-width:992px) {
  .divCopyCol{text-align: center;}
.divDesignCol{text-align: center;}
}

.divHomeNewBanner{position: relative; height: 440px;width: 100%;background: url('../Image/newBanner.png');background-position: center!important;background-repeat: no-repeat!important;background-size: cover!important;}
.divHomeNewBannerTitle{text-align: center; font-weight: 700;font-size: 36px; color: #823134; position: absolute;top: 50%;left: 50%; transform: translate(-50%,-50%); }
.divHomeNewBannerTitleInnerContainer{position: relative;}
.divHomeNewBannerSVG1{position: absolute;left: -35px;top: 50%; transform: translate(0,-50%);}
.divHomeNewBannerSVG2{position: absolute;right:-35px;top: 50%; transform: translate(0,-50%);}


.divMyBannerRow{margin-top: 50px!important;}
.divMyBannerTitle{font-weight: 700;font-size: 30px; color: #000; letter-spacing: 1px;}
.divMyBannerDescription{font-weight: 400;font-size: 14px; color: #1F1F1F; margin-top: 30px; margin-bottom: 40px!important;font-family: 'Gilroy'!important;}
.divMyBannerButton, .divMyBannerButton:hover { font-weight: 600;font-size: 12px; color: #A63E42;  font-family: 'Gilroy'!important; border-bottom: 1px solid #A63E42;}

.divMyBannerPic{height: 570px!important; width: 100%!important;}
.About .divTheFSVG{left: 50%; top: 50%; transform: translate(-50%,-50%);}
.divTheFSVG{position: absolute; height: 300px;width: 300px; left: -100px; top: 50%; transform: translate(0,-50%); background:url('../Image/theFSVG.svg'); background-repeat:no-repeat!important; background-size:cover!important ; background-position: center!important;}
.divTheLSVG{position: absolute; height: 300px;width: 300px; right: -100px; top: 50%; transform: translate(0,-50%); background:url('../Image/theLSVG.svg'); background-repeat:no-repeat!important; background-size:cover!important ; background-position: center!important;}
@media(max-width:992px){
  .divTheFSVG{height:150px;width: 150px; left: -10px; }
  .divTheLSVG{ height: 150px;width: 150px; right: -10px; }

  .sliderLogoClass{display: none!important;}

  .divMobileMargin{margin-bottom: 50px;}

  .IndexSlider{padding-left: 0!important;padding-right: 0!important;}

  .divFooterSocialLinks{margin-bottom: 20px; width: 100%;}

.divDesignCol {display: block!important; text-align: center!important;}

.divMenuLogoContainer{text-align: left!important;}
.divLangRow{margin-left:2rem!important}

.nav__item1{display: none!important;}

.About .divTheFSVG{display: none;}


}



.divMenuLogoContainer{width: 100%;text-align: center;}

.aCloseMenu{color: #000;margin-left: 10px!important; display: flex!important;padding-bottom: 20px;margin-top: 20px; margin-bottom: 20px;border-bottom: 1px solid #F3F3F3;}
.aCloseMenu svg{margin-top: auto;margin-bottom: auto;}
.aCloseMenu div{color: #000;margin-left: 10px!important;margin-top: auto;margin-bottom: auto;}

.divLangRow{margin-left: 5rem;font-family: 'Gilroy'!important;font-size: 14px!important;font-weight: 600; color: #909090;}
.divLangRow span{ cursor: pointer!important;}
.divLangRow span.active{ color: #A63E42!important;}

.colorDiscount{ color: #A63E42!important;}
.colorDiscount:hover{ border-bottom: 3px solid #A63E42!important;}

.divSliderImage{width: 100%!important; height: 100%!important;background-size:cover!important ; background-position:center!important ;background-repeat: no-repeat!important;}

.shopPage__header.tittle-margin h2{text-align: left!important;}
.shopPage__header.tittle-margin p{text-align: left!important;margin-top: 30px; color: #000!important;font-family: 'Gilroy'!important;font-weight: 400;}

.pagination{justify-content: center!important;}

ul {
  list-style: none;
  padding: 0;
  font-family: 'Gilroy'!important;
}

ul.pagination li {
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  /* border: 1px solid #A63E42; */
  display: flex;
  justify-content: center;
  vertical-align:middle;
  margin-left: 4px;
  margin-right: 4px;
  color: #909090;
  font-weight: 600;
}

ul.pagination li a {
  text-decoration: none;
  color: #A63E42;
  line-height: 30px;
  vertical-align: middle;
  font-size: 14px;
}

ul.pagination li.active a {
  color: white;
}
ul.pagination li.active {
  background-color: #A63E42;
}

ul.pagination li a:hover,
ul.pagination li a.active {
  color: #A63E42;
}
ul.pagination li.active a:hover{
  color: #fff;

}

.page-selection {
  width: 48px;
  height: 30px;
  color: #A63E42;
}

.pagination-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.btn-first-page a{
  font-size: 10px!important;
}
.btn-last-page a{
  font-size: 10px!important;
}

.submit-button{
  background: #A63E42;
  color: white;
  text-transform: uppercase;
  text-align: center;
  padding-top: 16px;
  padding-bottom: 16px;
  border: none;
  outline: none;
  font-family: 'Gilroy'!important;
  font-weight: 500;
  font-weight: bold;
  display: block;
  width: 100%;
  border-radius: 0;
  margin-top: 1rem;
  cursor:pointer;
  /* width: 40%;
  margin: 0 auto; */
  transition: .3s ease-in;

}
.submit-button:hover{
  background: #A63E42;
  color: white;
  text-transform: uppercase;
  text-align: center;
  padding-top: 16px;
  padding-bottom: 16px;
  border: none;
  outline: none;
  font-family: 'Gilroy'!important;
  font-weight: 500;
  font-weight: bold;
  display: block;
  margin-top: 1rem;
  width: 100%;
  border-radius: 0;
  cursor:pointer;
  /* width: 40%;
  margin: 0 auto; */
  box-shadow: 0 1px 1px rgba(0,0,0,0.15), 
  0 2px 2px rgba(0,0,0,0.15), 
  0 4px 4px rgba(0,0,0,0.15), 
  0 8px 8px rgba(0,0,0,0.15);

}

.submit-button4,.submit-button4:hover{
  background-color: transparent;
  color: #646464;
  text-transform: uppercase;
  text-align: center;
  padding-top: 16px;
  padding-bottom: 16px;
  border: none;
  outline: none;
  margin-top: 1rem;
  font-family: 'Gilroy'!important;
  font-weight: 700;
  font-weight: bold;
  display: block;
  width: 100%;
  border:0;
  border-radius: 0;
  cursor: pointer;
  /* width: 40%;
  margin: 0 auto; */
  transition: .3s ease-in;


}

.submit-button2{
  background: #fff;
  color: #A63E42;
  text-transform: uppercase;
  text-align: center;
  padding-top: 16px;
  padding-bottom: 16px;
  border: none;
  outline: none;
  margin-top: 1rem;
  font-family: 'Gilroy'!important;
  font-weight: 700;
  font-weight: bold;
  display: block;
  width: 100%;
  border:1px solid #A63E42;
  border-radius: 0;
  cursor: pointer;
  /* width: 40%;
  margin: 0 auto; */
  transition: .3s ease-in;


}
.submit-button2:hover{
  background: #fff;
  color: #A63E42;
  text-transform: uppercase;
  text-align: center;
  padding-top: 16px;
  padding-bottom: 16px;
  border: none;
  outline: none;
  margin-top: 1rem;
  font-family: 'Gilroy'!important;
  font-weight: 700;
  font-weight: bold;
  display: block;
  width: 100%;
  border:1px solid #A63E42;
  border-radius: 0;
  cursor: pointer;
  /* width: 40%;
  margin: 0 auto; */
  box-shadow: 0 1px 1px rgba(0,0,0,0.15), 
  0 2px 2px rgba(0,0,0,0.15), 
  0 4px 4px rgba(0,0,0,0.15), 
  0 8px 8px rgba(0,0,0,0.15);

}

.popover .submit-button2{
  background: #fff;
  color: #000;
  text-transform: uppercase;
  text-align: center;
  padding-top: 16px;
  padding-bottom: 16px;
  border: none;
  outline: none;
  margin-top: 1rem;
  font-family: 'Gilroy'!important;
  font-weight: 700;
  font-weight: bold;
  display: block;
  width: 100%;
  border:1px solid #000;
  border-radius: 0;
  cursor: pointer;
  /* width: 40%;
  margin: 0 auto; */
 
}

.popover .submit-button2:hover{
  background: #fff;
  color: #000;
  text-transform: uppercase;
  text-align: center;
  padding-top: 16px;
  padding-bottom: 16px;
  border: none;
  outline: none;
  margin-top: 1rem;
  font-family: 'Gilroy'!important;
  font-weight: 700;
  font-weight: bold;
  display: block;
  width: 100%;
  border:1px solid #000;
  border-radius: 0;
  cursor: pointer;
  /* width: 40%;
  margin: 0 auto; */
  box-shadow: 0 1px 1px rgba(0,0,0,0.15), 
  0 2px 2px rgba(0,0,0,0.15), 
  0 4px 4px rgba(0,0,0,0.15), 
  0 8px 8px rgba(0,0,0,0.15);
}

.divTopLine{border-top: 1px solid #C4C4C4;width: 100%!important;}
.divTopLine .container{padding-top: 25px!important;}

.divColFilter{background: #FAFAFA!important;padding-left: 30px!important;padding-right: 30px!important;}

@media(max-width:992px){
  .divColFilter{
    margin-bottom: 15px;
  }
  .divMyProgressSpan{font-size: 12px;}
  .divFilterSelect{font-size: 12px;}
}

.categoryFilter__labelFont2{font-family: 'Gilroy';font-weight: 600;font-size: 12px;width: 100%;margin-left: -15px!important; color: #000;padding-top: 20px;padding-bottom: 20px;border-bottom: 1px solid #C4C4C4;}

.divMySelectRow{font-family: 'Gilroy'!important;margin-bottom: 25px!important;font-weight: 600;}
.input-range__label-container{font-family: 'Gilroy'!important;font-weight: 600;}
.divMyProgressSpan{font-family: 'Gilroy'!important;font-weight: 500;}
.divFilterSelect{font-family: 'Gilroy'!important;font-weight: 500;border: none !important;}
.divFilterSelect .css-3mg4lg-control, .divFilterSelect .css-3mg4lg-control:focus,.divFilterSelect .css-3mg4lg-control:active{border: none !important;}
.divFilterSelect .css-1pnb1sr-control, .divFilterSelect .css-1pnb1sr-control:focus,.divFilterSelect .css-1pnb1sr-control:active{border: none !important;}

.divFilterSelect svg{color: #000!important;}

.divFilterOpenButton{color: #000;font-family: 'Gilroy'!important;font-size: 12px; font-weight: 600;margin-bottom: 15px;margin-left: 15px; margin-right: 15px; width: 100%; text-align: center;border:1px solid #DEDEDE;padding-top: 18px; padding-bottom: 18px;}

.countItemInBasket {color: #fff!important; background:#A63E42 ; width: 16px!important;height: 16px!important;font-size: 12px!important; text-align: center;font-family: 'Gilroy';border-radius: 50%;}

.product-detail__body{font-family: 'Gilroy';}
.product-detail__body .product-card__title{font-size: 20px;font-weight: 500;color: #000;margin-bottom: 30px!important;}
.product-detail__body .product-card__discountAmount{position: relative!important;margin-left: 0;width: auto;padding-top: 4px;padding-bottom: 4px;padding-left: 12px;padding-right: 12px;}
.divSplitBlock{margin-bottom: 30px;}

.product-detail__body .product-card__addFavorite{position: absolute;right: 0;top: 0;border: none!important;padding: 0;}

.productDetailButtons{display: flex;}
.product-detail__body .submit-button{display: block!important;width: 50%;padding-top: 18px;margin-left: 6px;}
.product-detail__body .submit-button2 {display: block!important;width: 50%;margin-right: 6px;}

.product-detail__body .submit-button2 span{display: flex;justify-content: center;}
.product-detail__body .submit-button2 svg{margin-top: auto;margin-bottom: auto;}

.divProductDetailSelectLabel{color: #C4C4C4;font-family: 'Gilroy';font-size: 15px;font-weight: 600;width: 25%;}
.productDetailSelect{width: 50%;}




.product-detail__body .css-10z4wgn-placeholder{left: 50%!important;transform: translate(-50%,-50%);width: 100%;color: #000;font-family: 'Gilroy';font-weight: 600;font-size: 12px;}
.product-detail__body .css-1uccc91-singleValue{left: 50%!important;transform: translate(-50%,-50%);width: 100%;color: #000;font-family: 'Gilroy';font-weight: 600;font-size: 12px;}
.divProductQuatity{display: flex;width: 50%;border:1px solid #DEDEDE;padding-top: 8px!important;padding-bottom: 8px!important;justify-content: center;align-items: center;}
.divProductQuatity .spanMinus{user-select: none; cursor: pointer; color: #646464;margin-right: auto!important;margin-left: 10px; font-size: 30px!important;font-weight: 400!important;line-height: 0;}
.divProductQuatity .spanPlus{user-select: none; cursor: pointer; color: #646464;margin-left: auto!important;margin-right: 10px; font-size:30px!important;font-weight: 400!important;line-height: 0;}
.divProductQuatity .divCount{color: #000;margin-left: auto;margin-right: auto;font-size: 13px!important;font-family: 'Gilroy'; font-weight: 600!important;}
.divProductDetailBorder{border-top: 1px solid #F3F3F3;border-bottom: 1px solid #F3F3F3;padding-top: 24px;padding-bottom: 24px;margin-top: 24px;}


.mainslider .slick-dots li::before{content: ''!important;}
.mainslider .slick-dots li.slick-active button:before{opacity: 0!important;}
.mainslider .slick-dots li{border:1px solid #000;height: 10px!important;width: 10px!important;border-radius: 50%;}
.mainslider .slick-dots li.slick-active{background-color:rgba(0,0,0,1);}
.breadcrumb-item a, .breadcrumb-item a span{vertical-align: middle;margin-top: auto!important;margin-bottom: auto!important;}

.spanNoStock{color: #A63E42;font-weight: 500;font-size: 12px;}

.divSepetQuantityBorder{border:1px solid #DEDEDE!important;padding-top: 8px;padding-bottom: 8px;padding-left: 3px;padding-right: 3px;}

.divAthena{font-family: 'Athena'!important;}
.divGilroy{font-family: 'Gilroy'!important;}
.divVideoPlayer{height: 560px!important;width: 100%!important;}

@media (max-width:992px) {
  .product-detail__body .product-card__title{margin-top: 30px;}
  .productDetailButtons{display: block;}
  .product-detail__body .submit-button{display: block!important;width: 100%;padding-top: 18px;margin-left: 0!important;}
  .product-detail__body .submit-button2 {display: block!important;width: 100%;margin-right: 0!important;}
  .productDetailSelect{width: 100%;}
  .divProductQuatity{width: 100%;}

  .breadcrumb-item a, .breadcrumb-item a span{font-size: 12px!important;vertical-align: middle;}

  .IndexSlider{margin-top: -20px;}

  .UserDetail__sectionSub2{display: block!important;}

  .spanLabel{display: inline-block!important;}
.spanLabel2{display: inline-block!important;}

.spanLabel3{margin: 10px; width: 80%; text-align: center;}
.OrderDetail__ArrowRight{position:relative !important;text-align: right;}

.spanMenu{display: none!important;}

.divVideoPlayer{height:300px!important;}

.divFooterLang{right: 50%!important; transform: translate(50%,20px);  }



}
.UserDetail__sectionSub2{display: flex;}

.spanLabel{color: #909090!important;font-weight: 500!important;font-size: 12px!important;font-family: 'Gilroy' ;}
.spanLabel2{color: #000!important;font-weight: 500!important;font-size: 12px!important;font-family: 'Gilroy' ;margin-left: 6px;}
.spanLabel3{background: #F3F3F3!important;border: 1px solid #C4C4C4; border-radius: 30px; padding-left:40px;padding-right:40px;font-weight: 500;}

.AdresBilgisi{padding-bottom: 50px;}

.cursorPointer{cursor:pointer !important;}

.divOrderDetailAdress{padding-top: 10px; border-top: 2px solid #F3F3F3;}

.selectBorderless{color: 000!important;border:none!important}
.selectBorderless option{color: unset!important;border:none!important;margin-left: 6px;}

.divFormTitle{font-weight: 700;font-size: 14px;font-family: 'Gilroy' ;text-align: center;}
.divFormSubTitle{font-weight: 700;font-size: 12px;font-family: 'Gilroy' ;text-align: left;margin-top: 28px;}
.divFromInput{margin-top: 12px;min-height: 48px!important;border-radius:4px!important;font-family: 'Gilroy';}
.divFormSifremiUnuttum{font-weight: 700;font-size: 12px; color: #6B7070; text-align: center;margin-top: 25px;cursor: pointer;}

.divShade{position: absolute;left: 0!important; top: 0!important;width: 100%!important;height: 100%!important;background-color: rgba(0,0,0,0.3); transition: .3s ease;}
.imgPlay{cursor:pointer; position:absolute;top: 50%;left: 50%;transform: translate(-50%,-50%); height: 50px!important; width: 50px!important;}
.About .styles_playButton__1Q3EF{background: none!important;background-color:none!important ; top: 50%!important;left: 50%!important;transform: translate(-50%,-50%)!important;margin: 0!important;}
.About .styles_playButton__1Q3EF::after{content: url('../Image/playCircle.svg')!important;height: 50px!important;width: 50px!important;
  border-right: none; 
  border-left: unset!important; 
  border-top: unset!important; 
  border-bottom: unset!important;
  left: unset!important; 
  top: unset!important; 
  margin-left: unset!important; 
  margin-top: unset!important; 
  border-left-width:unset!important; 
  content: "";
  position: absolute;
}
.About .react-player__shadow{height: unset!important;width: unset!important;}
.About .react-player__play-icon{background: url('../Image/playCircle.svg')!important;height: 80px!important;width: 80px!important;background-size: cover!important;background-repeat: no-repeat!important;
  border-style: unset!important;
  border-width: unset!important;
  border-color: unset!important;
  margin-left: unset!important;



}

.divFontColorSquare{height: 45px;width: 45px;margin-right: 20px;cursor: pointer;position: relative;box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; transition: .3s ease-in;}
.disableFontSquare{display: block; position: absolute;top: 50%;left: 50%;font-weight: 500; color:#fff; transform: translate(-50%,-50%); padding: 2px;background-color: rgba(0, 0, 0, 0.3); font-size:10px; width: 100%;height: 100%;user-select: none!important;transition: .3s ease-in;}
.divFontColorSquare:hover {box-shadow: none!important; }

.selectedSquare{height: 45px;width: 45px;margin-right: 20px;cursor: pointer;position: relative;box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 3px, rgba(6, 24, 44, 0.65) 0px 5px 7px -1px, rgba(255, 255, 255, 0.08) 0px 3px 0px inset; transition: .3s ease-in;background-position: center!important;background-size: cover!important;background-repeat: no-repeat!important;}


.divPhotoSquare{height: 45px;width: 45px;margin-right: 20px;cursor: pointer;position: relative;box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; transition: .3s ease-in;background-position: center!important;background-size: cover!important;background-repeat: no-repeat!important;}
.disablePhotoSquare{background-size: cover!important;background-repeat: no-repeat!important; display: block; position: absolute;top: 50%;left: 50%;font-weight: 500; color:#fff; transform: translate(-50%,-50%); padding: 2px;background-color: rgba(0, 0, 0, 0.3); font-size: 10px; width: 100%;height: 100%;user-select: none!important;transition: .3s ease-in;}
.divPhotoSquare:hover {box-shadow: none!important; }

.text-red-color{color: #A63E42!important;}
.cursor-pointer{cursor: pointer !important;}

.divFooterLang{display: flex;font-family: 'Gilroy'; position: absolute;right: 15px; bottom: -10px; color: #fff; }
.divFooterLangItem{margin-left: 10px;  }
.divFooterLangItem.active{color: #A63E42;  }

.MusteriHizmetleri__body .MusteriHizmetleri__border{background:#FAFAFA; border-radius: 2px;margin-bottom: -150px;}
.MusteriHizmetleri__body .css-za34sp-control{padding: 0!important;border: none!important;}
.MusteriHizmetleri__body .css-1uru93y-placeholder{color: unset;}
.MusteriHizmetleri__body .css-1bd3czz-control{margin: 0!important;padding: 0!important;border: none!important;}
.MusteriHizmetleri iframe{border:none!important;margin-bottom: -5px;}

.SignUp{background-image: url('../Image/loginSVG.svg'); background-position: center!important;background-size:contain!important ;background-repeat: no-repeat!important;}
.modal-content{background-image: url('../Image/loginSVG.svg'); background-origin: content-box; background-position: center!important;background-size:contain!important ;background-repeat: no-repeat!important;}
.UyeliksizAlisveris{padding-bottom: 50px;padding-top: 50px; background-image: url('../Image/loginSVG.svg'); background-origin: content-box; background-position: center!important;background-size:contain!important ;background-repeat: no-repeat!important;}


.disabledInput{opacity: 0.8!important;}
.disabledInput:hover{box-shadow: none!important;cursor: default!important;}

.imgSafeShop{height: 50px;object-fit: contain; margin-top: auto; margin-left: auto; margin-bottom: 20px; margin-right: -5px;}

.divContactRemainingText{font-family: 'Gilroy'; font-size: 12px;}

.divNewInstallmentRow{display: flex;width: 100%!important; max-height: 1500px; overflow-y: auto;flex-wrap: wrap;}
.divNewInstallmentCol{width: 11%!important;border:1px solid #f1f1f1; }
.divNewInstalmentColCell{padding: 10px;font-size: 10px; text-align: center;border-top: 1px solid #f1f1f1;}
.divNewInstalmentColImg{width: 100%; height: 30px; object-fit: contain;}

@media (max-width:992px) {
.divNewInstallmentCol{width: 33%!important; }
}

.login-bg-image{
  background: url('../Image/body-bg-img.jpg'); 
  position: relative;
  background-repeat:no-repeat;
  background-position: top center ; 
  background-size: cover;
  width: 100vw;
  height: 100vh;
  outline: none;
}
.login-bg-image-overlay{
  position: absolute;
  background:  linear-gradient(180deg, rgba(74, 26, 11, 0.14) 0%, rgba(74, 26, 11, 0.78) 100%);
  inset: 0;
  z-index: 10;
}

.mt-0{
  margin-top: 0!important;
}
.input-container{
  padding-top: 64px;
  position: relative;
  z-index: 20;
  text-align: center;
}
.login-ferual-text{
  margin-bottom: 16vh;
}
.submit-button-register{
  background: var(--secondary-color);
  color: white;
  text-transform: uppercase;
  text-align: center;
  border: none;
  outline: none;
  font-family: 'Gilroy';
  font-weight: 600;
  display: block;
  width: 100%;
  height: 42px;
  font-size: 12px;
  line-height: 15px;
}

.submit-button-register:hover{
  box-shadow: 0 1px 1px rgba(0,0,0,0.15), 
  0 2px 2px rgba(0,0,0,0.15), 
  0 4px 4px rgba(0,0,0,0.15), 
  0 8px 8px rgba(0,0,0,0.15);
  transition: box-shadow 0.2s linear;
}
